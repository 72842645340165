@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-white dark:bg-gray-800;
  }

  h1 {
    @apply text-2xl font-bold;
  }
}
